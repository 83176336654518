"use strict";
import { getCookie } from "./getcookie.js";
import A11yDialog from "a11y-dialog";

const logoutLinks = document.querySelectorAll('a[data-link="logout-link"]');
const menuButton = document.querySelector(".hamburger-menu-button");
const container = document.querySelector("#mobile-menu");
const dialog = new A11yDialog(container);
const lines = document.querySelectorAll(".menu-icon line");

document.addEventListener("DOMContentLoaded", function () {
  logoutLinks.forEach((logoutLink) => {
    logoutLink.addEventListener("click", handleLogout);
  });

  menuButton.addEventListener("click", function () {
    if (dialog.shown) {
      dialog.hide();
    } else {
      dialog.show();
    }
  });

  dialog.on("show", function () {
    animateMenuIcon();
  });

  dialog.on("hide", function () {
    revertMenuIcon();
  });
});

export function handleLogout(event) {
  event.preventDefault();
  const logoutLink = event.currentTarget;
  const url = logoutLink.getAttribute("data-url");
  const redirectUrl = logoutLink.getAttribute("data-redirect-url");

  fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-CSRFToken": getCookie("csrftoken"),
    },
  })
    .then((response) => {
      if (response.ok) {
        window.location.href = redirectUrl;
      } else {
        console.error("Logout failed");
      }
    })
    .catch((error) => {
      console.error("Error:", error);
    });
}

function animateMenuIcon() {
  lines.forEach((line) => {
    line.style.transformOrigin = "center";
    line.style.transition = "all 300ms ease-in-out";
  });
  lines[0].style.transform = "rotate(45deg) translate(0, 20px)";
  lines[1].style.opacity = 0;
  lines[2].style.transform = "rotate(-45deg) translate(0, -20px)";
}

function revertMenuIcon() {
  lines.forEach((line) => {
    line.style.transformOrigin = "";
    line.style.transition = "all 200ms ease-in-out";
  });
  lines[0].style.transform = "";
  lines[1].style.opacity = 1;
  lines[2].style.transform = "";
}
